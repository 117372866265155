.RotateMoveRight {
    position: relative;
    animation: RotateMoveLeft 10s linear infinite;
}

@keyframes RotateMoveRight {
    0%   {right: 0px;}
    50%   {right: 40px;}
    100%  {right: 0px;}
}
.servicefir {
    position: relative;
    overflow: hidden;
  }
  
.blur-background1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://cdn.svgator.com/images/2023/03/animated-geometric-shapes-background.svg') center/cover no-repeat;
    filter: blur(4px)  brightness(0.4);
    -webkit-filter: blur(4px)  brightness(0.4);
  
   
  }
  
  .servicefir .container {
    position: relative;
    z-index: 1;
  }
  
  .servicefir .accordion-body p{
    color: black;
  }

  .firstservicehead {
    color: black;
    font-size: 30px;
  }