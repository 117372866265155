#playArea {
  position: relative; /*absolute*/
  top :0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background: transparent;
}

canvas {
  width: 100%;
  
}

@media only screen and (min-width: 1200px) {
  .welcome-right{
    margin-left: 350px !important;
  }
}

.welcome-right{
  
  margin-top: -150px;
}

@media (max-width: 768px) {
  #playArea {
   
    
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) {
  #playArea {
   
    
    width: 100%;
   
  }
  
  .light-welcomediv{
    position: relative;
    background-color: #0a254d;
  }

  .dark-welcomediv {
    position: relative;
  }

  
  .dark-welcome-area{
    position: absolute;
    z-index: 9999;
    
    transform: translate(-0%, -185%);
  }

  .light-welcome-area{
  position: absolute;
  z-index: 9999;
  
  transform: translate(-0%, -185%);
  }

}

@media only screen and (min-width: 600px) {

  #playArea {
   
    
    width: 100%;
   
  }
  
  .light-welcomediv{
    position: relative;
    background-color: #0a254d;
  }

  .dark-welcomediv {
    position: relative;
  }

}

@media only screen and (min-width: 1200px) {
  #playArea {
   
    
    width: 100%;
   
  }
  
.light-welcomediv{
  position: relative;
  background-color: #0a254d;
}

.dark-welcomediv {
  position: relative;
}
.welcome-btn{
  margin-left: 80px;
}
}

@media only screen and (min-width: 992px) {
  #playArea {
   
    
    width: 100%;
   
  }
  
.light-welcomediv{
  position: relative;
  background-color: #0a254d;
}

.dark-welcomediv {
  position: relative;
}

}

#welcomediv{

  background-image: url('../images/Frontpageimage.jpg');
  background-size: 100% 110%;
  background-repeat: no-repeat;
  background-blend-mode: overlay !important; 
  filter: brightness(0.7);
}

@media only screen and (max-width: 600px){
  .welcome-right{
    margin-top: -350px;
    margin-left: -60px;
    width: 100%;
  }

  .light-welcome-text h1{
    font-size: 20px !important;
    text-align: center !important;
  }

  .welcome-btn{
   margin-right: 0px;
  }
 
}


@media (min-width: 2560px) {

  .welcome-area{
    transform: translate(-0%, -430%) !important;
  }
}