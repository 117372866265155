.service-v2-card-title {
  color: #0f2168;
  margin-bottom: 32px;
  font-weight: 600;
  line-height: 96%;
  
}

.service-v2-card-title.white {
  color: #e9edfd;
}

.service-v2-card-details {
  color: #595959;
  font-size: 14px;
  font-weight: 500;
  line-height: 175%;
}

.service-v2-card-details.white {
  color: #bac7f8;
}

.service-v2-bottom-wrap {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 700svh;
}

.service-v2-flex-wrap {
  grid-column-gap: 102px;
  grid-row-gap: 102px;
  display: flex;
  bottom: 0;
}

.service-v2-content-wrap {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 392px;
  display: flex;
  bottom: 0;
  margin-top: 100px;
  margin-left: 15%;
}

.service-v2-sticky-wrap {
  margin-top: auto;
  margin-bottom: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 8svh;
}

.service-v2-main-wrap {
  border-radius: var(--radius-32px);

  /* height: 773px; */
  /* flex-direction: column; */
  /* width: 100%;
  
  display: flex;
  position: sticky;
  overflow: hidden; */
}

.service-v2-single-wrap {
  
  background-image: url("../images/Group\ 4.png");

  background-repeat: no-repeat;
  background-size: auto;
  justify-content: center;
  align-items: center;
  padding: 208px 64px 94px 159px;
}

.section1 {
  bottom: 0;
  height: 100vh;
  z-index: 10;
  padding: 1rem;
  width: 100%;
  background-image: url("../images/Servi-1.png");
 
  background-repeat: no-repeat;
  background-size:cover;
  position: sticky;
    
  } 

  .services-label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #065f46; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }

  .services-label1 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #2D2D55; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }

  .services-label2 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #6A4944; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }

 .section1 p{
    color: black;
  }

  .servi p{
    margin-top: 80px;
    width: 80%;
    text-align: justify;
  }
  
  .servi h2{
    margin-top: 70px;

  }
 .section2 {
    bottom: 0;
   
    height: 100vh;
    width: 100%;
    z-index: 7;
    padding: 1rem;
    background-image: url("../images/Servi-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
    
  }
  
  .section3 {
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 6;
    padding: 1rem;
    background-image: url("../images/Servi-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
  }
  
  .section4 {
      bottom: 0;
      z-index: 5;
      padding: 1rem;
      background-image: url("../images/Servi-4.png");
      position: sticky;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
   
  }

  .services-label3 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #1A3649; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }

  .section5 {
      bottom: 0;
      z-index: 4;
      height: 100vh;
      width: 100%;
      padding: 1rem;
      background-image: url("../images/Servi-5.png");
      position: sticky;
      background-repeat: no-repeat;
      background-size: cover  ;
    
    
  }

  
  .services-label4 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #755F46; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }

  .section6 {
      bottom: 0;
     z-index: 3;
     height: 100vh;
     width: 100%;
     padding: 1rem;
     background-image: url("../images/Servi-6.png");
     position: sticky;
     background-repeat: no-repeat;
     background-size: cover;
  }

  .services-label5 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #B49595; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }


  .section7 {
    bottom: 0;
    height: 100vh;
    padding: 1rem;
    position: sticky;
    z-index: 2;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/Servi-7.png");
    
  }

  .services-label6 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    text-align: center;
    padding: 10px;
    background-color: #4A5F4D; 
    color: white;
    font-size: 30px; 
    border-radius: 5px;
    height: 500px;
  }

  .services-svg {
      z-index: -1;
      color: #f3f3f3;
      object-fit: contain;
      height: 40em;
      min-height: 86%;
      position: absolute;
      top: 12%;
      bottom: 0%;
      left: 0%;
      right: auto;
    }
    
    .services-svg._2 {
      opacity: .02;
      left: 0;
    }
    
    .services-svg._3 {
      opacity: .02;
      left: 0;
    }
    
    .services-svg._1 {
      top: 7%;
      left: 8px;
    }
    
    .services-svg._1.main {
      top: 9%;
    }

    /* .servicontainer3 {
      max-width: 1270px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 15px;
      padding-right: 15px;
    } */

    .service-dot-wrap {
      display: none;
      z-index: 99;
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      /* display: flex; */
      position: fixed;
      top: 0%;
      bottom: 0%;
      left: 67px;
      right: auto;
    }
    
    .service-single-dot {
      border-radius: var(--radius-16px);
      background-color: #d9d9d9;
      width: 8px;
      height: 8px;
    }


    .primary-button-wrap {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      border: 2px solid var(--blue-500);
      background-color: var(--blue-400);
      color: var(--white-200);
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      font-weight: 600;
      line-height: 175%;
      transition: all .5s;
      display: flex;
    }
    
    .primary-button-wrap:hover {
      transform: rotate(3deg);
      box-shadow: 3px 3px #1a38ae;
    }
    
    .primary-button-wrap._02 {
      background-color: var(--white);
      color: #060606;
      border-color: #142c8b;
    }
    
    .primary-button-wrap._02:hover {
      box-shadow: 3px 3px #0f2168;
    }
    
   
    @media screen and (max-width: 991px) {

      .service-v2-content-wrap {
          max-width: 335px;
        }

        .service-v2-content-wrap._01 {
          grid-column-gap: 60px;
          grid-row-gap: 60px;
        }

        .services-svg {
          color: #e9edfd;
          transform: rotate(-90deg);
        }
      
        .services-svg._2, .services-svg._3 {
          opacity: .06;
          top: auto;
          bottom: -33%;
          left: 42%;
          right: 0%;
        }
      
        .services-svg._1 {
          opacity: .6;
          top: auto;
          bottom: -33%;
          left: 42%;
          right: 0%;
        }
      
        .services-svg._1.main {
          top: auto;
          bottom: -33%;
          left: 42%;
          right: 0%;
        }
      }

       
.section.service-v2 {
  z-index: 10;
  background-color: var(--white);
  /* background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65d4b7adfa81c2d4a826aa67_Background%20Light.png"); */
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 64px;
  padding-bottom: 132px;
  position: relative;
}

.service-v2-lottie-wrap {
  border-radius: var(--radius-16px);
  background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda043_Service%20V2%20Content%20BG.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 450px;
  display: flex;
  overflow: hidden;
  margin-top: 170px;
}

.service-v2-lottie-wrap._02 {
  background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda05a_Service%20V2%20Single%20Image%20Bg%20Black.jpg");
}

.service-v2-lottie-wrap._03 {
  background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda059_Service%20V2%20Single%20Image%20Bg%20Blue.jpg");
}

.service-v2-lottie-wrap._05 {
  background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda05a_Service%20V2%20Single%20Image%20Bg%20Black.jpg");
}

.service-v2-lottie-wrap._06 {
  background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda059_Service%20V2%20Single%20Image%20Bg%20Blue.jpg");
}

.service-v2-lottie-wrap .CloudMigration{

 
 
 
}

.service-v2-lottie-wrap._04{
  /* width: 465px;
  height: 460px;
  margin-left: 20px; */
}

@media (max-width: 768px) {

}

@media only screen and (max-width: 600px){
  .service-v2-single-wrap{
    width: 100% !important;
  }

  .service-v2{
    height: 7200px;
  }
  .section1{
    height: 1000px;
  }

  .service-v2-flex-wrap{
    width: 100%;
  }

  .service-v2-content-wrap {
    margin-top: -120px;
    width: 100%;
  }

  .service-v2-content-wrap h2{
    font-size: 25px;
  }

  .service-v2-content-wrap p{
    margin-top: 0px;
    font-size: 15px;
    
    
  }

  .section1 .service-v2-card-details-wrap{
    text-align: center !important;
  }

  /* .section1 .service-v2-card-details-wrap h2{
   
    width: 100% !important;
    text-align: left !important;
    align-items: center !important;
  } */

  .service-v2-card-details-wrap h2{
    align-items: left;
    text-align: justify !important;
    left: 0 !important;
    margin-right: 10px;
  }

  .service-v2-lottie-wrap {
    margin-top: 280px;
    
    width: 150px !important;
    height: 150px !important;
  }

  .service-v2-lottie-wrap img{
    width: 100px !important;
    height: 100px !important;

  }

  .section2{
    height: 1000px;
  }
  .section3{
    height: 1000px;
  }
  .section4{
    height: 1000px;
  }
  .section5{
    height: 1000px;
  }
  .section6{
    height: 1000px;
  }
  .section7{
    height: 1000px;
  }
}

@media (max-width: 768px) {
  .servicontainer3 {
    width: 100%;
   
  
  }
  .service-v2-flex-wrap {
    flex-direction: column-reverse; 
    align-items: center;
  }

  .service-v2-lottie-wrap {
    order: 2;
    
  }

  .service-v2-content-wrap {
    order: 1;
   
    
  }

  .services-label,.services-label1,.services-label2,.services-label3,
  .services-label4,.services-label5,.services-label6 {
    display: none; 
  }

  .service-v2-lottie-wrap._04{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._04 img{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._05{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._05 img{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._03{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._03 img{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._01{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._01 img{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._02{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._02 img{
    width: 300px;
    height: 300px;
  }
  .service-v2-lottie-wrap._06{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap._06 img{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap{
    width: 300px;
    height: 300px;
  }

  .service-v2-lottie-wrap img{
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 992px) {
  .servicontainer3 {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .CloudMigration img{
    width: 400px;
    height: 400px;
  }
  .service-v2-lottie-wrap._05 img{
    width: 400px;
    height: 400px;
  }
  .service-v2-lottie-wrap._03 img{
    width: 400px;
    height: 400px;
  }
  .service-v2-lottie-wrap._01 img{
    width: 400px;
    height: 400px;
  }
  .service-v2-lottie-wrap._02 img{
    width: 400px;
    height: 400px;
  }
  .service-v2-lottie-wrap._06 img{
    width: 400px;
    height: 400px;
  }
  .service-v2-lottie-wrap img{
    width: 400px;
    height: 400px;
  }
}


@media (min-width: 2560px) {

  .service-v2-single-wrap{
    height: auto;
  }

  .service-v2-bottom-wrap {
    height: auto !important;
  }
}