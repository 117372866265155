
/*Name:ICOCyption html5 tamplate*/


/*
================+/+/+/+/+/+/+/+/+/=================
                    medium
================+/+/+/+/+/+/+/+/+/=================
*/

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    .gradient-btn.apps-btn i {
        font-size: 12px;
        padding-right: 5px;
    }
    .main-menu li a {
        font-size: 11px;
    }
    .welcome-text h1 {
        font-size: 24px;
    }
    a.gradient-btn {
        font-size: 10px;
    }
    .about-mid-text h1 {
        font-size: 24px;
    }
    .ico-heading h1 {
        font-size: 28px;
    }
    .progress {
        height: 40px;
    }
    .heading h1 {
        font-size: 24px;
    }
    .single-team-social li a {
        font-size: 22px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        margin: 0 5px;
    }
    .logo-area img {
        width: 90px;
        height: 25px;
    }
    .custom-progressBar::before {
        bottom: -80%;
        font-size: 24px;
    }
    .custom-progressBar::after {
        bottom: -80%;
        font-size: 24px;
    }
    .about-mid-img {
        width: 500px;
    }
    .v2.welcome-progress #days, .v2.welcome-progress #minutes, .v2.welcome-progress #hours, .v2.welcome-progress #seconds {
        font-size: 20px;
        margin: 13px 0;
        display: block;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        line-height: 50px;
        text-align: center;
    }
    .distibution-list-1 {
        padding-top: 12%;
    }
    .distibution-list-2 {
        padding-top: 16%;
    }
    .distibution-list-3 {
        padding-top: 4%;
    }
    .distibution-list-4 {
        padding-top: 16%;
    }
    .single-roadmap.road-left::before {
        height: 69%;
    }
    .single-roadmap.road-left::after {
        width: 125%;
        top: 20.5%;
        right: -26%;
    }
    .single-roadmap.road-right::before {
        height: 91.5%;
        bottom: -19%;
        left: 15%;
    }
    .single-roadmap.road-right::after {
        width: 96.5%;
        bottom: -19%;
        right: -11.7%;
    }
    a.gradient-btn {
        padding: 13px;
    }
    .single-blog-social li a {
        margin-bottom: 20px;
    }
}

/*
================+/+/+/+/+/+/+/+/+/=================
                     tab
================+/+/+/+/+/+/+/+/+/=================
*/

@media only screen and (min-width: 768px) and (max-width: 991px) {

    a.single-about {
        width: 400px;
        margin: 0 auto;
    }
    .welcome-right {
        text-align: center;
        padding: 50px 0;
    }
    .slicknav_menu {
        padding-top: 15px;
    }
    .distibution-list-1 {
        padding-top: 20%;
    }
    .distibution-list-2 {
        padding-top: 31%;
    }
    .distibution-list-3 {
        padding-top: 11%;
    }
    .distibution-list-4 {
        padding-top: 29%;
    }
    .distibution-list-5 {
        padding-top: 0%;
        padding-right: 38%;
    }
    .distibution-list-6 {
        padding-top: 14%;
        padding-right: 46%;
    }
    .distibution-list-7 {
        padding-top: 13%;
        padding-right: 59%;
    }
    .distibution-list-8 {
        padding-top: 13%;
        padding-right: 70%;
    }
    .distibution-d.item-1 {
        padding-left: 50px;
    }
}

/*
================+/+/+/+/+/+/+/+/+/=================
                    Mobile menu
================+/+/+/+/+/+/+/+/+/=================
*/

@media only screen and (max-width: 991px) {
    
    .welcome-text h1 {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
    }
    .welcome-text h4 {
        font-size: 13px;
    }
    .gradient-btn.v2 {
        margin-bottom: 20px;
    }
    .gradient-btn.welcome-btn {
        margin-bottom: 10px;
    }
    .abour-area {
        text-align: center;
    }
    a.single-about {
        margin-bottom: 30px;
    }
    .single-document {
        margin-bottom: 30px;
    }
    .single-team {
    	margin-bottom: 50px;
    }
    .apps-area {
        text-align: center;
    }
    .apps-area .heading {
        margin: 50px 0;
    }
    body {
        overflow-x: hidden;
    }
    .welcome-area::before {
        display: none;
    }
    .about-mid-img {
        width: 400px;
    }
    .about-mid-text h1 {
        font-size: 22px;
    }
    .custom-progressBar::before, 
    .custom-progressBar::after {
        font-size: 24px;
    }
    .single-cup {
        margin-top: 8%;
    }
    .progress-details {
        right: -6%;
    }
    .progress {
        height: 50px;
    }
    .heading h1 {
        font-size: 28px;
    }
    .single-team-content h3 {
        font-size: 20px;
    }
    .single-team-content h6 {
        font-size: 12px;
    }
    .ico-heading h1 {
        font-size: 24px;
    }
    .welcome-area.v2 .offset-1{
        margin: 0;
    }
    .v2.welcome-progress {
        width: 440px;
        margin: 0 auto;
            margin-top: 0px;
        margin-top: 40px;
    }
    .demoOne-details, .demo-details.demoTwo {
        width: 330px;
        margin: 0 auto;
    }
    .contact-details {
        text-align: center;
        margin-top: 60px;
    }
    .community-area.v2 .single-community i {
        width: 60px;
        height: 60px;
        font-size: 18px;
        line-height: 60px;
    }
    .distibution-d li span {
        font-size: 28px;
        font-weight: 700;
    }
    .distibution-d li {
        font-size: 12px;
        color: #fff;
    }
    .distibution-svg img {
        width: 400px;
    }
    .single-roadmap.road-left::before {
        height: 68.5%;
        bottom: 11%;
        left: 1%;
    }
    .single-roadmap.road-left::after {
        width: 121.5%;
        top: 20.5%;
        right: -22.5%;
    }
    .single-roadmap.road-right::before {
        height: 87.8%;
        bottom: -14%;
        left: 13.2%;
    }
    .single-roadmap.road-right::after {
        width: 96.5%;
        bottom: -14%;
        right: -10%;
    }
    .gradient-btn.apps-btn i {
        font-size: 12px;
        padding-right: 5px;
    }
    .gradient-btn.apps-btn{
        font-size: 12px;
        margin-bottom: 40px;
    }
    .apps-img img {
        width: 300px;
    }
    .apps-area .offset-1 {
        margin-left: 0;
    }
    .welcome-img {
        width: 100%;
    }
    .logo-area {
        text-align: center;
    }
    .community-area.v2 .offset-2{
        margin: 0;
    }
    .logo-area.footer {
        text-align: unset;
    }
    .apps-area.section-padding{
        padding-top: 0;
    }
    .single-blog-social li a {
        margin-bottom: 20px;
    }
    .single-blog-area.section-padding{
        padding-bottom: 0;
    }
}



/*
================+/+/+/+/+/+/+/+/+/=================
                    Mobile
================+/+/+/+/+/+/+/+/+/=================
*/

@media only screen and (min-width: 0px) and (max-width: 767px) {

    .heading h1 {
        font-size: 18px;
    }
    .logo-area img {
        width: 80px;
        height: 25px;
    }
    a.gradient-btn {
        font-size: 6px;
    }
    .welcome-text h1 {
        font-size: 14px;
        line-height: 20px;
    }
    .welcome-text h4 {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 25px;
        line-height: 16px;
    }
    .about-mid-text h1 {
        font-size: 16px;
    }
    .gradient-btn.about-btn i {
        font-size: 8px;
    }
    .single-team {
        margin-bottom: 40px;
    }
    .progress {
        height: 30px;
    }
    .about-mid-img {
        position: relative;
        left: 0;
        margin-right: 0;
        margin-bottom: 40px;
    }
    .about-area {
        text-align: center;
    }
    .readmore-btn {
        font-size: 13px;
    }
    .readmore-btn i {
        font-size: 13px;
        padding-right: 0;
    }
    a.single-about {
        width: 100%;
        margin: 0 auto;
            margin-bottom: 40px;
        padding: 20px;
    }
    .single-about-img img {
        width: 110px;
        height: 70px;
    }
    .single-about-text h4 {
        font-size: 14px;
    }
    a.single-about {
        font-size: 11px;
    }
    .welcome-text h1 {
        font-size: 18px;
        line-height: 22px;
    }
    a.gradient-btn {
        font-size: 6px;
        padding: 10px 5px;
    }
    .about-mid-img {
        width: 260px;
        top: -17%;
        margin: auto;
            margin-bottom: auto;
        margin-bottom: 30px;
    }
    #days, 
    #minutes, 
    #hours, 
    #seconds {
        font-size: 28px;
    }
    .single-ico h5 {
        font-size: 10px;
    }
    .custom-progressBar::before, .custom-progressBar::after {
        font-size: 16px;
        bottom: -65%;
    }
    .single-cup p {
        font-size: 12px;
    }
    .progress-details {
        top: 150%;
        line-height: 33px;
        height: 30px;
    }
    .faq-list ul li a {
        font-size: 10px;
        margin: 0 5px;
    }
    .single-faq h4, .faq-area .owl-nav div  {
        font-size: 13px;
    }
    .single-faq p{
        font-size: 12px;
    }
    .faq-area .owl-nav .owl-prev {
        right: 10%;
    }
    .faq-area .owl-nav .owl-prev:hover {
        right: 11%;
    }
    .single-community.big-social i {
        height: 90px;
        width: 90px;
        line-height: 90px;
        font-size: 30px;
    }
    .single-community.big-social i:hover {
        font-size: 25px;
    }
    .single-community.mid-social i {
        height: 70px;
        width: 70px;
        line-height: 70px;
        font-size: 25px;
    }
    .single-community.mid-social i:hover {
        font-size: 20px;
    }
    .single-community a.flickr i {
        background: #F93FA2;
    }
    .single-community i:hover {
        background: transparent !important;
        border-color: #fff;
        font-size: 20px;
        transition: all .3s;
    }
    .single-community i {
        width: 50px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
    }
    .single-community i:hover {
        font-size: 16px;
    }
    .community-area::before {
        width: 150%;
    }
    .ico-area::before {
        width: 150%;
    }
    .ico-heading h1 {
        font-size: 22px;
    }
    .single-details a {
        font-size: 18px;
    }
    .welcome-area .offset-1{
        margin: 0;
    }
    .contact-form input, .contact-form textarea {
        padding: 10px;
    }
    .distibution-d li span {
        font-size: 16px;
    }
    .distibution-d li{
        font-size: 9px;
    }
    .distibution-d.item-1 {
        padding-left: 24%;
    }
    .distibution-list-1 {
        padding-top: 23%;
    }
    .distibution-list-2 {
        padding-top: 29%;
    }
    .distibution-list-3 {
        padding-top: 10%;
    }
    .distibution-list-4 {
        padding-top: 26%;
    }
    .distibution-list-5 {
        padding-top: 1%;
        padding-right: 56%;
    }
    .distibution-list-6 {
        padding-top: 7%;
        padding-right: 60%;
    }
    .distibution-list-7 {
        padding-top: 8%;
        padding-right: 69%;
    }
    .distibution-list-8 {
        padding-top: 8%;
        padding-right: 75%;
    }
    .single-roadmap.road-left::before, 
    .single-roadmap.road-left::after, 
    .single-roadmap.road-right::before, 
    .single-roadmap.road-right::after {
    display: none;
    }
    .owl-carousel .owl-item .single-roadmap-img img {
        width: 100px;
        height: 100px;
    }
    .single-roadmap.road-left {
        padding-left: 0;
    }
    .roadmap-area .owl-nav div {
        font-size: 14px;
    }
    .roadmap-item {
        padding: 30px;
    }

    .heading h5 {
        font-size: 13px;
        text-transform: uppercase;
    }
    .apps-area p {
        font-size: 10px;
    }
    .gradient-btn.v2 {
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        padding: 0 15px;
    }
    .apps-img img {
        width: 200px;
    }
    .welcome-right {
        text-align: center;
        padding: 50px 0;
    }
    .single-footer li a {
        font-size: 14px;
    }
    .single-cup {
        margin-top: 15%;
    }
    .progress-details {
        right: -16%;
    }
    .roadmap-text {
        font-size: 12px;
    }
    .roadmap-text h5{
        font-size: 14px;
    }
    .welcome-area.v2 {
        padding-top: 100px;
    }
    .faq-area .col-12.offset-1, .community-area .col-lg-5.offset-2{
        margin: 0;
    }
    .gradient-btn.apps-btn.apps-btn-2 {
        margin-left: 0;
    }
    .gradient-btn.subscribe {
        padding: 13px;
        font-size: 10px;
    }
    .v2.welcome-progress {
        width: 100%;
    }
    .v2.welcome-progress h4 {
        font-size: 14px;
    }
    .v2.welcome-progress .progress-time h5 {
        font-size: 10px;
        text-align: unset;
    }
    .v2.welcome-progress #days, 
    .v2.welcome-progress #minutes, 
    .v2.welcome-progress #hours, 
    .v2.welcome-progress #seconds {
        font-size: 16px;
        height: 40px;
        width: 40px;
        line-height: 42px;
        margin-bottom: 10px;
    }
    .single-prgress-w p {
        font-size: 12px;
    }
    .progress.v2 {
        height: 20px;
    }
    .progress.v2 .progress-bar::after {
        height: 40px;
        width: 40px;
    }
    .single-prgress-b p {
        font-size: 11px;
    }
    .faq-area.v2 .container{
        max-width: 100%;
        padding: 0;
    }
    .progress-time .col {
        padding: 0;
    }
    .apps-area {
        padding-top: 0;
    }
    .faq-area .col-12 {
        padding: 0;
    }
    .distibution-svg.distibution-svg-1 img {
        width: 160px;
        height: 260px;
    }
    .distibution-svg img {
        width: 230px;
    }
    .distibution-d.distibution-d-2 {
        position: absolute;
        left: -30%;
        width: 575px;
        z-index: 999999999;
    }
    .faq-area.v2 .btn.btn-link {
        font-size: 12px;
    }
    .btn.btn-link::after {
        left: -5%;
        top: -12%;
    }
    .card-body p {
        font-size: 12px;
    }
    .single-blog-area .offset-1{
        margin: 0;
    }
    .quite-text h4{
        font-size: 14px;
    }
    .single-blog-social {
        text-align: center;
    }
    
    .single-blog-contact input:nth-child(2) {
        margin-left:0;
    }
    
    .comment-text-form a.gradient-btn {
        font-size: 8px;
        padding: 16px 12px;
    }
    .comment-text-form input {
        width: 62%;
        padding: 9px;
    }

    .comment-text-form {
        text-align: center;
    }

    .comment-img {
        width: 100%;
    }
    .single-comment {
        height: 500px;
    }
    .blog-comment p{
        font-size: 14px;
    }
    .comment-text-form {
        width: 100%;
    }
    .single-blog-contact input {
        width: 100%;
        margin-bottom: 20px;
    }
}

/*
================+/+/+/+/+/+/+/+/+/=================
                    wid device
================+/+/+/+/+/+/+/+/+/=================
*/

@media only screen and (min-width: 480px) and (max-width: 767px) {

    .single-footer input {
        width:70%;
    }
    a.gradient-btn {
        padding: 10px 20px;
        font-size: 10px;
    }
    .faq-area .owl-nav .owl-prev {
        right: 7%;
    }
    .faq-area .owl-nav .owl-prev:hover {
        right: 8%;
    }
    .single-ico h5 {
        font-size: 13px;
    }
    .v2.welcome-progress {
        width:100%;
        margin:0 auto;
    }
    a.single-about {
        width: 100%;
        font-size: 14px;
        padding: 40px 60px;
    }
    .single-about-text h4 {
        font-size: 18px;
    }
    .welcome-text h1 {
        font-size: 24px;
        line-height: 36px;
    }
    .welcome-text h4 {
        font-size: 13px;
        margin-bottom: 35px;
        line-height: 20px;
    }
    .logo-area img {
        width: 90px;
        height: 30px;
    }
    .slicknav_menu {
        padding-top: 8px;
    }
    .gradient-btn.v2 {
        height: 40px;
        line-height: 40px;
    }
    .about-mid-img {
        width: 100%;
        top: 0%;
        margin-bottom: 80px;
    }
    .heading h5 {
        font-size: 16px;
    }
    .heading h1 {
        font-size: 20px;
    }
    .about-mid-text h1 {
        font-size: 20px;
    }
    .gradient-btn.about-btn i {
        font-size: 13px;
    }
    .single-about-img img {
        width: 130px;
        height: 90px;
    }
    .single-team {
        width: 350px;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .apps-area p {
        font-size: 15px;
    }
    .apps-img img {
        width: 400px;
        margin-top: 50px;
    }
    .faq-list ul li a {
        font-size: 14px;
        margin: 0 10px
    }
    a.gradient-btn {
        padding: 10px;
    }
    .gradient-btn.apps-btn.apps-btn-2 {
        margin-left: 10px;
    }
    .v2.welcome-progress #days, .v2.welcome-progress #minutes, .v2.welcome-progress #hours, .v2.welcome-progress #seconds {
        margin: 0 auto;
        font-size: 30px;
        display: block;
        height: 70px;
        width: 70px;
        border-radius: 50px;
        line-height: 70px;
        text-align: center;

    }
    .v2.welcome-progress .progress-time h5 {
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
    }
    .distibution-svg.distibution-svg-1 img {
        width: 300px;
        height: 310px;
    }
    .distibution-svg img {
        width: 400px;
    }
    .distibution-d.distibution-d-2 {
        left: 93%;
    }
    .distibution-d li {
        font-size: 12px;
    }
    .distibution-list-5 {
        padding-top: 1%;
        padding-right: 47%;
    }
    .distibution-list-6 {
        padding-top: 17%;
        padding-right: 54%;
    }
    .distibution-list-7 {
        padding-top: 16%;
        padding-right: 68%;
    }
    .distibution-list-8 {
        padding-top: 17%;
        padding-right: 78%;
    }
    .btn.btn-link::after {
        left: -2%;
    }
    .progress-details {
        right: -9%;
    }
    .gradient-btn.apps-btn.apps-btn-2 {
        margin-left: 10px;
    }
    .single-comment {
        height: 400px;
    }
}

/*=========================================================================
                                The end
==========================================================================*/