@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');



.custom-containerauth {
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 550px;
}

.custom-containerauth p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
    color: white;
}

.custom-containerauth span {
    font-size: 12px;
}

.custom-containerauth a {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

.custom-containerauth button {
    background: -webkit-linear-gradient(right,#4867cf,#fa768d,#fe998b);
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.custom-containerauth button.hidden {
    background-color: transparent;
    border-color: #fff;
}

.custom-containerauth form {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.custom-containerauth input {
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    color: black;
}

.custom-form-containerauth {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.custom-sign-in {
    left: 0;
    width: 50%;
    z-index: 2;
}

.custom-containerauth.active .custom-sign-in {
    transform: translateX(100%);
}

.custom-sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.custom-containerauth.active .custom-sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }
    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.custom-social-icons {
    margin: 20px 0;
}

.custom-social-icons a {
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}
/* tppa */
.custom-toggle-containerauth {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.custom-containerauth.active .custom-toggle-containerauth {
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.custom-toggle {
    background-color: #512da8;
    height: 100%;
    background: #faeede;
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.custom-containerauth.active .custom-toggle {
    transform: translateX(50%);
}

.custom-toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}
/* sign in page button comes up */
.custom-toggle-left {
    position: absolute;
    transform: translateX(-200%);
    z-index: 10;
       
}

.custom-containerauth.active .custom-toggle-left {
    transform: translateX(0);
}

.custom-toggle-right {
    right: 0;
    transform: translateX(0);
}

.custom-containerauth.active .custom-toggle-right {
    transform: translateX(200%);
}




