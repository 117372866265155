
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,800');

body{
	/* background: #faeede; */
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	
  overflow-x: hidden;

}

h1,
h2,
h3,
h4,
h5,
h6
{
	
	font-family: 'Montserrat', sans-serif;
	text-transform: capitalize;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

a, a:hover{
  text-decoration: none;
}

input:focus, button:focus, textarea:focus {
  outline: none
}

input, textarea{
  color: #fff;
  text-transform: capitalize;
}

a.gradient-btn:hover:after, 
a.gradient-btn:after,
.single-details a,  
.single-details a:hover, 
.about-area.v2::after, 
.slicknav_nav a:hover, 
.slicknav_nav .slicknav_row, .slicknav_nav a, 
.single-footer li a, 
.single-footer li a:hover,  
.single-community i, 
.single-community i:hover, 
.gradient-btn.apps-btn.apps-btn-2, 
.gradient-btn.apps-btn.apps-btn-2:hover, 
.faq-area .owl-nav div, 
.faq-area .owl-nav div:hover, 
.readmore-btn, 
.readmore-btn i, 
.readmore-btn:hover, 
.single-team-social li a:hover, 
.single-team-social li a:hover i, 
 .single-team-social a i, 
.single-team-social li a, 
.roadmap-area .owl-nav div,   
.roadmap-area .owl-nav div:hover,  
.single-document:hover, 
.single-document:hover .single-document-text span, 
.document-flag img, 
.single-ico a:hover, 
.ico-heading h1 a, 
.ico-heading h1 a:hover, 
.main-menu li ul, 
.header-area, 
.main-menu li:hover ul,  
.main-menu li ul li a:hover, 
a.single-about:hover, 
.header-area.header-absolate.skrollable.skrollable-after,
a.single-about, 
.main-menu li ul li a
 {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

/*===================
     header css
=====================*/

.logo-area img {
  width: 110px;
  
  height: 80px;
}

.header-area {
  top: 0;
  padding-top: 10px;
 
  position:sticky;
  width: 100%;
  
}

.light-mode .light-welcomenav{
  position: relative;
  background-color: #00021c;
}

.light-header-area{
 
  border-color: #fff;
  z-index: 1500000;
}

.light-header-area .light-text li a{
  color: black;
}

.navbarheadtitle .light-text li a {
  color: white;
}


.light-header-area .user-menu {
  color: black;
}


.light-header{
 
  border-color: #fff;
  z-index: 1500000;
}

.light-header .light-text li a{
  color: #5c4aa3;
}

.light-header .user-menu {
  color: #5c4aa3;
}

.dark-header-area{
 
  z-index: 1500000;
}

.dark-header{
  background-color: black;
  z-index: 1500000;
}

.header-area.header-absolate.skrollable.skrollable-after {
  padding-top: 15px;
  
  padding-bottom: 15px;
}

.main-menu li {
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-top: 10px;
}

.main-menu li.active a{
  color: #FB9A8C;
}

.main-menu li a {
  display: block;
  padding: 20px;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
}

.background-header .light-text li a{
  margin-bottom: 10px;
  color: black;
}

.navtext li a{
  color: white;
}

.dark-text li a{
  color: white;
}

.main-menu li ul {
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 999;
  background: #001763;
  border-radius: 5px;
  visibility: hidden;;
  opacity: 1;
}

.main-menu li ul li a {
  width: 150px;
  padding: 10px;
  text-align: left;
  display: block;
  border-radius: 5px;
}

.main-menu li ul li a:hover {
  background: #062489;
  padding-left: 15px;
}

.main-menu li:hover ul {
  opacity: 1;
  visibility: visible;
  top: 52px;
  left: 0;
}

.main-menu li.active ul li a {
  color: #fff;
}

/*===================
     welcome css
=====================*/

#particles-js {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

 
}

.welcome-area {
  transform: translate(-0%, -165%) !important;
}

.welcome-area h1{
  font-size: 25px !important;
}

.dark-welcome-area{
	position: absolute;
	z-index: 9999;
  
  transform: translate(-0%, -185%);
}

.light-welcome-area{
 position: absolute; /*absolute*/
 z-index: 9999;
 
 transform: translate(-0%, -185%);
 
}

/* #welcomediv {
  background-color: #0a254d;
} */



.light-welcomediv{
  position: relative;
  background-color: #0a254d;
  overflow: hidden;
 /*Added height:"700px"*/
} 

.dark-welcomediv {
  height: 700px;
  position: relative;
}

.welcome-text h4 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 50px;
  line-height: 26px;
}

.light-welcome-text h4{
  
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 50px;
  line-height: 26px;
}

.dark-welcome-text h4{
 
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 50px;
  line-height: 26px;
}


.welcome-text h1 {
  font-size: 43px;
  font-weight: 700;
  line-height: 50px;
}

.light-welcome-text h1{
 
  font-size: 43px;
  font-weight: 700;
  line-height: 50px;
}

.dark-welcome-text h1{
  color: white;
  font-size: 43px;
  font-weight: 700;
  line-height: 50px;
}

.welcome-btn {
  margin-right: 10px;
}

.welcome-area::before {
  position: absolute;
  width: 100%;
  height: 161%;
  content: "";
  /* background: url(./assets/img/Group1.png) no-repeat; */
 
  top: -62%;
  left: -29%;
  z-index: -1;
}

.gradient-btn.v2 {
  height: 55px;
  line-height: 36px;
  padding-left: 30px;
  padding-right: 30px;
}

form .gradient-btn.v2 {
  height: 55px;
  line-height: 36px;
  padding-left: 30px;
  padding-right: 30px;
}

/*===================
     about css
=====================*/

.about-area {
  position: relative;
  z-index: 2;
  height: 650px;
  width: 100%;
}

.aboutalign {
  margin-top: 60px;
}

.light-about-area  h5{
  padding-top: 10px;
  color: white;
}

.dark-about-area  h5{
  padding-top: 10px;
  color: white;
}

.light-about-area h1{
  color: white;
}

.dark-about-area h1{
  color: white;
}

.light-about-area p{
  color: white;
}

.light-about-area {
  background: linear-gradient(0deg,#0a254d,#0a254d);
}

.dark-about-area {
  
  background: linear-gradient(0deg, #000, black);
}


.dark-about-area:before, .dark-about-area:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
  
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}


/* .light-about-area {
  
  background: linear-gradient(0deg, #f9f5f5, rgb(231, 225, 225));
} */


.light-about-area:before, .light-about-area:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
	z-index: -1;
	animation: steam 60s linear infinite;
}


@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.dark-about-area:after {
	filter: blur(10px);
}


.about-area::after {
  position: relative;
  content: "";
  height: 140%;
  width: 100%;
  right: -38%;
  bottom: -60%;
  /* background: url(./assets/img/about-bg.png) no-repeat; */
  -webkit-transform: rotate(14deg);
  z-index: -1;
}

.singleicon {
  width: 80px; 
  height: 80px;
  max-width: 100%; 
  overflow: hidden;
  display: flex; 
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {

 

  .aboutcircle {
    margin-left: 30%;
  }
  .singleicon {
    width:100px;
    height:100px;
  }
  .single-about-text h4 {
    margin-left: 30%;
  }


}

.aboutcircle {
  width: 100px; 
  height: 100px;
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
 
}



.single-about-area {
  
  
  z-index: 2;
  position: relative;
}

.light-single-about-area {
  background-color: #f6f6f6;
  opacity: 1;
  background-image: radial-gradient(#4444ee 1.6500000000000001px, #f6f6f6 1.6500000000000001px);
  background-size: 33px 33px;
}

.dark-single-about-area {
  background-color: black;
}

.single-logo-wrapper {
  display: table;
  height: 60px;
  width: 100%;
}

.single-item {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.owl-carousel .owl-item .single-item img {
  width: auto;
  display: inline-block;
}

.about-mid-text h1 {
  font-size: 31px;
  text-transform: capitalize;
  font-weight: 700;
  margin: 20px 0;
}

.about-mid-img {
  position: relative;
  left: -18%;
  margin-right: 30px;
  height: 350px;
  width: 500px;
  animation: about-mid-img 3s ease-in 1s 1 normal forwards;
}

@media (max-width: 768px) {
  .about-mid-img {
    left: 0; 
    margin-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    height: auto;
    animation: none;
  }

  .about-area  .aboutalign .about-areadiv {
    width: 80%; 
    margin: 0 auto;
  }
}


/* Copy this code after the above code */
@keyframes about-mid-img {
  0% {
	transform: translateX(0);
  }

  100% {
	transform: translateX(100px);
  }
}



/* Copy this code after the above code */
@keyframes about-mid-text {
  0% {
    transform: translateX(0);
    }
  
    100% {
    transform: translateX(-40px);
    }
  }
  

.gradient-btn.about-btn i{
  padding-right: 5px;
  font-size: 16px;
}

.single-about-img img {
  width: 162px;
  height: 110px;
}

.single-about-text h4 {
  font-weight: 600;
  color: white;
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 28px;
  padding-bottom: 8px;
}

.single-about-text p{
  font-weight: 400;
  line-height: 22px;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 16px;
  max-width: 470px;
}

a.single-about {
  color: #cbe5ff;
  display: block;
  min-height: 250px;
  height: 100%;
	width: 100%;
  text-align: center;
	background-color: rgba(255, 255, 255, 0.06);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	padding: 50px 25px;
	-webkit-box-shadow: 0 25px 23px rgba(0, 0, 0, 0.15);
	box-shadow: 0 25px 23px rgba(0, 0, 0, 0.15);
	border: 1.5px solid rgba(255, 255, 255, 0.06);
  border-radius: 1rem;
}

a.single-about:hover {
 
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

/*===================
ico area css
======================*/

.ico-area {
  background: #031b69;
}


.ico-heading h1 {
  font-size: 36px;
  text-transform: capitalize;
  font-weight: 700;
}

.ico-heading h1 a {
  color: #f9748f;
  text-transform: uppercase;
}

.ico-heading h1 a:hover {
  color: #F6D266;
}

.ico-area h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

.single-ico a {
  color: #f9748f;
  padding-right: 10px;
  font-size: 18px;
}
.single-ico h1 {
  font-size: 36px;
  font-weight: 700;
}

.single-ico h5 {
  font-size: 18px;
}

.single-ico a:hover {
  color: #F6D266;
}

.progress {
  height: 60px;
  border-radius: 50px;
  margin-top: 50px;
  background: #004cff;
  overflow: unset;
}

.progress-d-top {
  position: absolute;
  top: -33%;
  left: 38%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #004cff;
}
.progress-bar.progress-bar-success {
  background: -webkit-linear-gradient(left,#f6d365,#fda085);
  position: relative;
  z-index: 2;
  border-radius: 50px 0px 0 50px;
}

.progress-details {
  position: absolute;
  right: -4%;
  top: 135%;
  height: 45px;
  width: 70px;
  background: #004cff;
  line-height: 47px;
  border-radius: 5px;
  font-weight: 700;
  animation: upDown 2s infinite;
  text-align: center;
}

.ico-area {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.ico-area:before {
  position: absolute;
  content: "";
  background: url(assets/img/liveShape-bg.svg) no-repeat;
  height: 100%;
  width: 100%;
  left: -30%;
  bottom: -41%;
}

.ico-area:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: -82%;
  top: -40%;
  background: url(assets/img/liveShape-bg.svg) no-repeat;
  z-index: -1;
}

#days, #minutes, #hours, #seconds {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.custom-progressBar{
  position: relative;
  z-index: 2;
}

.custom-progressBar::before {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  left: 5%;
  bottom: -61%;
  font-size: 30px;
  width: 50px;
  animation: upDown 2s infinite;
  color: red;
  font-weight: 700;
}
.custom-progressBar::after {
  position: absolute;
  content: "\f106";
  font-family: FontAwesome;
  right: 5%;
  bottom: -61%;
  color: red;
  font-size: 30px;
  font-weight: 700;
  animation: upDown 2s infinite;

}

.single-cup {
  margin-left: 6%;
  margin-top: 6%;
}

.single-cup p {
  font-size: 16px;
  text-transform: capitalize;
}

.single-cup.right {
  margin-left: 0;
  margin-right: 6%;
}

/*===================
Documentation area css
======================*/

.single-document-text span {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.heading h1 {
  font-size: 36px;
  font-weight: 700;
}

.single-document:hover .document-flag img {
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}

.single-document:hover .single-document-text span {
  color: #FB9A8C;
}

button.single-document-text {
  background: none;
  border: none;
  cursor: pointer;
}

/*===================
roadmap area css
======================*/

.roadmap-area{
	background: #031b69;
  overflow: hidden;
}

.single-roadmap-img img {
  width: 120px;
  height: 120px;
}

.roadmap-text h5 {
  font-size: 18px;
  font-weight: 700;
}

.single-roadmap.road-right .col-5, .single-roadmap.road-right .col-7 {
  padding: 0;
}

.single-roadmap {
  padding: 20px 15px;
}

.single-roadmap.road-left {
  position: relative;
  z-index: 2;
  padding-left: 24%;
}

.single-roadmap.road-left::before {
  position: absolute;
  content: "";
  height: 66.5%;
  width: 1px;
  background: #004cff;
  bottom: 10.5%;
  left: 1%;
  z-index: -1;
}

.single-roadmap.road-left::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 120%;
  background: #004cff;
  top: 23%;
  right: -21%;
  z-index: -1;
}

.single-roadmap.road-right {
  position: relative;
  z-index: 2;
  padding-top: 29%;
  padding-left: 0;
  padding-right: 0;
}

.single-roadmap.road-right::before {
  position: absolute;
  content: "";
  height: 74.5%;
  width: 1px;
  background: #004cff;
  bottom: 0;
  left: 12.5%;
  z-index: -1;
}

.single-roadmap.road-right::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 97.5%;
  background: #004cff;
  bottom: 0;
  right: -10%;
  z-index: -1;
}

.owl-carousel .owl-item .single-roadmap-img img {
  width: auto;
  display: inline-block;
}

.roadmap-area .owl-nav div {
  position: absolute;
  right: 0;
  font-size: 20px;
  color: #fff;
}

.roadmap-area .owl-nav .owl-prev {
  left: auto;
  right: 5%;
}

.roadmap-area .owl-nav div:hover{
  color: #fa758e;
}

.roadmap-area .owl-carousel .owl-stage-outer {
  overflow: visible;
}

/*===================
team area css
======================*/

.single-team-img img {
  border-radius: 35px;
}

.single-team-content h3 {
  font-size: 22px;
  font-weight: 700;
}

.single-team-social li {
  display: inline-block;
}

.single-team-social li a {
  color: #fff;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: block;
  line-height: 50px;
  background: red;
  margin: 0 5px;
  border-radius: 10px;
  border: 1px solid transparent;
}

.single-team-social li a:hover i {
  padding-left: 10px;
}

.single-team-social li a:hover {
  border-color: #fff;
  background: none !important;
}

.single-team-social li a.ico-1 {
  background: #0077b5;
}

.single-team-social li a.ico-2 {
  background: #E84C88;
}

.single-team-social li a.ico-3 {
  background: #55acee;
}

/*===================
faq area css
======================*/

.faq-list .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: inline-block;
}

.faq-list li {
  display: inline-block;
}

.faq-list ul li a.active {
  color: #fa758e;
}

.faq-list ul li a {
  display: block;
  margin: 0 10px;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
}

.single-faq {
  padding: 35px 20px;
  background: #143dc7;
}

.readmore-btn {
  display: inline-block;
  color: #fa758e;
  text-transform: capitalize;
  font-size: 16px;
}

.readmore-btn i {
  font-size: 19px;
  margin-right: 5px;
}
.readmore-btn:hover {
  color: #fff;
}

.readmore-btn:hover i{
  margin-right: 10px;
}

.faq-area .owl-nav div {
  position: absolute;
  left: auto;
  right: 2%;
  bottom: -16%;
  font-size: 20px;
}

.faq-area .owl-nav .owl-prev {
  right: 5%;
}

.faq-area .owl-nav div:hover{
  color: #fa758e;
}

.single-faq h4 {
  font-size: 22px;
  font-weight: 600;
}

/*===================
team bg area css
======================*/

.team-bg {
  position: relative;
  z-index: 2;
}

.team-bg::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  /* background: url(./assets/img/bg.png);
      background-attachment: scroll; */
  z-index: -1;
  background-attachment: fixed;
}

/*===================
distibution area css
======================*/

.distibution-svg img {
  width: 700px;
}

.distibution-svg.distibution-svg-1 img {
  width: 400px;
}

.distibution-bg {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.distibution-bg:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  /* background: url(./assets/img/initial-bg.png) no-repeat; */
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
}

.distibution-bg:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -33%;
  right: -32%;
  /* background: url(./assets/img/documentaion-bg.png) no-repeat; */
  z-index: -1;
}

.distibution-d li {
  font-size: 18px;
  color: #fff;
}

.distibution-d li span {
  font-size: 36px;
  font-weight: 700;
}


.distibution-list-1 {
  padding-top: 10%;
}

.distibution-list-2 {
  padding-top: 13%;
}

.distibution-list-3 {
  padding-top: 4%;
}

.distibution-list-4 {
  padding-top: 13%;
}

.distibution-svg.distibution-svg-2 {
  position: relative;
  z-index: 2;
}

.distibution-d.distibution-d-2 {
  position: absolute;
  left: 93%;
  width: 575px;
  z-index: 999999999;
}

.distibution-list-5 {
  padding-top: 3%;
  padding-right: 2%;
}

.distibution-list-6 {
  padding-top: 27%;
  padding-right: 16%;
}

.distibution-list-7 {
  padding-top: 25%;
  padding-right: 40%;
}

.distibution-list-8 {
  padding-top: 26%;
  padding-right: 58%;
}

/*===================
apps area css
======================*/

.gradient-btn.apps-btn {
  padding: 25px;
}

.gradient-btn.apps-btn i{
  font-size: 20px;
  padding-right: 10px;
}

.gradient-btn.apps-btn {
  padding: 20px 30px;
  text-transform: capitalize;
}

.gradient-btn.apps-btn.apps-btn-2{
  margin-left: 10px;
  background: transparent;
  border: 2px solid #1452e2;
}

.apps-area p{
  font-size: 16px;
  color: #fff;
}

.light-apps-area h5{
  color: black;
}

.dark-apps-area h5{
  color: white;
}

.light-apps-area h1{
  color: black;
}

.dark-apps-area h1{
  color: white;
}

.light-apps-area p{
  color: black;
}

.apps-img img {
  width: 400px;
}

/*===================
community area css
======================*/

.community-area {
  position: relative;
  z-index: 2;
  background: #031b69;
  overflow:hidden;
}

.community-area:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  bottom: -40%;
  left: -24%;
  background: url(assets/img/liveShape-bg.svg) no-repeat;
  z-index: -1;
}

.community-area:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -31%;
  right: -84%;
  z-index: -1;
  background: url(assets/img/liveShape-bg.svg) no-repeat;
}

.single-community i {
  display: block;
  width: 80px;
  height: 80px;
  color: #fff;
  font-size: 24px;
  background: red;
  text-align: center;
  line-height: 80px;
  margin: 10px;
  float: right;
  border-radius: 50%;
  border: 1px solid transparent;
}

.single-community i:hover{
  background: transparent !important;
  border-color: #fff;
  font-size: 20px;
}

.single-community.mid-social i {
  float: left;
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 30px;
}

.single-community.mid-social i:hover {
  font-size: 25px;
}

.single-community.big-social i:hover{
  font-size: 30px;
}

.single-community.big-social i {
  float: left;
  height: 120px;
  width: 120px;
  line-height: 120px;
  font-size: 40px;
}

.single-community a.google-plus i {
  background: #EA4335;
}

.single-community a.linkedin i {
  background: #0e76a8;
}

.single-community a.dribbble i {
  background: #E84C88;
}

.single-community a.github i {
  background: #171515;
}

.single-community a.behance i {
  background: #B4B4B4;
}

.single-community a.youtube i {
  background: #ff0000;
}

.single-community a.twitter i {
  background: #1da1f2;
}

.single-community a.flickr i {
  background: #F93FA2;
}

/*===================
footer area css
======================*/

.single-footer .logo-area img {
  width: 90px;
}

@media (max-width: 768px) {
  .single-footer .logo-area img{
    width: 100px;
    height: 100px;
  }
  
}

.single-footer li a {
  display: inline-block;
  margin-bottom: 10px;
}

.single-footer  ul{
  margin-left: 10px;
  margin-top: 20px;
}
/* 
.light-single-footer li a{
  color: black;
}

.light-single-footer p{
  color: black;
}

.light-single-footer h4{
  color: black;
} */


/* 
.dark-single-footer li a{
  color: white;
}

.dark-single-footer h4{
  color: white;
}

.dark-single-footer p{
  color: white;
} */

.single-footer input {
  background: none;
  border: none;
  width: 78%;
  padding: 10px;
}

.single-footer .footer-form{
  background: #031b69;
  border-radius: 5px;
}


.footer-form a {
  float: right;
  width: 20%;
}
.single-footer li a:hover {
  padding-left: 5px;
  color: #FB9A8C;
}

/*===================
mobile menu css
======================*/


.slicknav_btn.slicknav_collapsed {
  margin: 0;
  padding: 0;
  background: none;
  width: 100%;
}

.slicknav_icon {
  margin: 0;
}

.slicknav_menu {
  background: none;
}

.slicknav_btn.slicknav_open {
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slicknav_nav {
  background: #000D47;
  width: 200px;
  position: absolute;
  left: auto;
  right: auto;
  top: 50px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.slicknav_menu .slicknav_menutxt {
  display: none;
}

.slicknav_nav .slicknav_row, .slicknav_nav a {
  padding: 10px 20px;
  margin: 0;
}

.slicknav_nav a:hover {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #004AFF;
  color: #fff;
  padding-left: 25px;
}

.slicknav_nav .slicknav_row:hover {
  background: #004AFF;
}
.slicknav_nav .slicknav_item a, .slicknav_nav .slicknav_parent-link a {
  text-transform: capitalize;
}

/*==========================================================================
homePage version 2 css
===========================================================================*/

/*===================
welcome area v2 css
======================*/

.welcome-area.v2 {
  padding: 90px 0;
      padding-top: 90px;
  padding-top: 160px;
}

.v2.welcome-progress {
  background: #001763;
  padding: 50px 30px;
  border-radius: 10px;
  box-shadow: 0 0 45px #0324af;
}

.progress.v2 {
  margin: 10px 0;
  height: 30px;
  background: #062489;
}

.v2.welcome-progress a.gradient-btn {
  width: 100%;
  padding: 15px 0px;
}

.progress.v2 .progress-bar {
  border-radius: 50px;
  background: -webkit-linear-gradient(top,#00bbf9,#005eea);
  position: relative;
  z-index: 2;
}

.progress.v2 .progress-bar:after {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  background: -webkit-linear-gradient(top,#00bbf9,#005eea);
  right: 0;
  border-radius: 50px;
}

.v2.welcome-progress h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}
.single-prgress-w p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
}

.single-prgress-b p {
  font-weight: 700;
  margin-top: 10px;

}

.v2.welcome-progress .progress-time h5 {
  font-size: 14px;
  text-align: center;
}

.v2.welcome-progress #days, .v2.welcome-progress #minutes, .v2.welcome-progress #hours, .v2.welcome-progress #seconds {
  font-size: 30px;
  margin: 13px 0;
  display: block;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  line-height: 70px;
  text-align: center;
}

.v2.welcome-progress #days{
  background: -webkit-linear-gradient(#00c0fa, #005dea);
  background: -o-linear-gradient(#00c0fa, #005dea);
  background: linear-gradient(#00c0fa, #005dea);
}

.v2.welcome-progress #minutes{
  background: -webkit-linear-gradient(#3ce6bc, #72afd3);
  background: -o-linear-gradient(#3ce6bc, #72afd3);
  background: linear-gradient(#3ce6bc, #72afd3);
}

.v2.welcome-progress #hours{
  background: -webkit-linear-gradient(#d8c3fc, #91c5fc);
  background: -o-linear-gradient(#d8c3fc, #91c5fc);
  background: linear-gradient(#d8c3fc, #91c5fc);
}

.v2.welcome-progress #seconds{
  background: -webkit-linear-gradient(#43b2f5, #19d7e4);
  background: -o-linear-gradient(#43b2f5, #19d7e4);
  background: linear-gradient(#43b2f5, #19d7e4);
}

/*===================
token area v2 css
======================*/

.about-area.v2::after {
  right: -28%;
  bottom: 22%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*===================
distibution area v2 css
======================*/

.distibution-bg.v2::after {
  bottom: 0%;
  right: -29%;
}


/*===================
faq v2 area css
======================*/

.faq-area.v2 .card-header:first-child {
  border-radius: none !important;
  border-radius: none;
  background: #031b6f;
  padding: 5px;
    padding-left: 5px;
  padding-left: 30px;
}

.faq-area.v2 .card {
  border: none;
  background: #143dc7;
  margin-bottom: 20px;
}

.faq-area.v2 .btn.btn-link {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.btn.btn-link{
  position: relative;
  z-index: 2;
}

.btn.btn-link::after {
  position: absolute;
  left: -3%;
  content: "+";
  height: 100%;
  width: 30px;
  text-align: center;
  top: 0;
  line-height: 35px;
  animation: upDown 2s infinite;
  font-size: 20px;
}

[aria-expanded="true"].btn.btn-link::after {
  content: "-";
}

/*===================
community v2 area css
======================*/

.community-area.v2 .single-community li{
  display: inline-block;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  background: none;
  border: 1px solid #163fcb;
  padding: 15px;
  text-transform: capitalize;
  border-radius: 5px;
}

.single-details small {
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
}

.single-details a{
  font-size: 24px;
  text-transform: capitalize;
  color: #fff;
}

.single-details a:hover {
  color: #FE978B;
  padding-left: 10px;
}

.community-area.v2::after {
  top: -14%;
  right: -73%;
}

.community-area.v2::before {
  bottom: -63%;
  left: -17%;
}

.single-footer .footer-form.v2{
  background: #062489;
}
/*==========================
blog page css
==========================*/
.welcome-header{
  padding: 150px 0;
  font-size: 18px;
  text-transform: capitalize;
  
}
.welcome-header a{
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.welcome-header a:hover{
  color: #fff;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.welcome-header i {
  padding: 0 10px;
}
.welcome-area.bv{
  background: url(assets/img/about-shap.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
}
.welcome-area.bv:after{
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  background: #000;
  opacity: .8;
  left: 0;
  top: 0;
  z-index: -1;
}
.single-blog-image {
  background: ;
  background-position: center !important;
  background-size: cover !important;
  height: 300px;
  border-radius: 10px 10px 0 0;

}
.blog-bg-1 {
  background: url(assets/img/blog-blog-1.jpg);
}
.blog-bg-2 {
  background: url(assets/img/blog-blog-2.jpg);
}
.blog-bg-3 {
  background: url(assets/img/blog-blog-3.jpg);
}
.blog-bg-4 {
  background: url(assets/img/blog-blog-4.jpg);
}
.blog-bg-5 {
  background: url(assets/img/blog-blog-5.jpg);
}
.blog-bg-6 {
  background: url(assets/img/blog-blog-6.jpg);
}
.blog-bg-7 {
  background: url(assets/img/blog-blog-7.jpg);
}
.blog-bg-8 {
  background: url(assets/img/blog-blog-8.jpg);
}
.blog-bg-9 {
  background: url(assets/img/blog-blog-4.jpg);
}

.single-blog-text a {
  color: #fff;
  font-size: 17px;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.single-blog-text a:hover {
  color: #F78A89;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.single-blog-text {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 0 2px #f2f2f2;
  border-radius: 0 0 10px 10px;
}
.single-blog{
  border-radius: 10px;
}

/*==========================
single-blog page css
==========================*/

a.single-blog-top:hover {
  color: #f38417;
}
.single-blog-h1 {
  font-size: 36px;

}
.single-blog-h4{
  font-size: 24px;
}

.single-blog-h1, .single-blog-h4{
  color: #fff;
  font-weight: 700;
}
.quite-area {
  background: #1938a2;
  padding: 30px 0;
}
.quite-text {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.quite-text i {
  font-size: 30px;
  color: #fff;
}

.single-blog-social li {
  display: inline-block;
}
.single-blog-social li a {
  display: block;
  color: #676767;
  background: #f8f8f8;
  font-size: ;
  text-transform: capitalize;
  height: 40px;
  width: 91px;
  text-align: center;
  line-height: 40px;
  margin-right: 8px;
  border-radius: 10px;
  border: 1px solid transparent;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.single-blog-social li a:hover{
  border-color: #f8f8f8;
  background: transparent;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.single-blog-social a.blog-234 {
  background: #22233b;
  color: #fff;
}
.single-blog-social a.blog-234:hover {
  background: #fff;
  color: #22233b;
}
.single-blog-social a.facebook-b {
  background: #3B5998;
  color: #fff;
}
.single-blog-social a.facebook-b:hover {
  background: transparent;
  color: #3B5998;
}
.single-blog-social a.camera-b {
  background: #c32aa3;
  color: #fff;
}
.single-blog-social a.camera-b:hover {
  background: transparent;
  color: #c32aa3;
}
.single-blog-social a.twitter-b {
  background: #1da1f2;
  color: #fff;
}
.single-blog-social a.twitter-b:hover {
  background: transparent;
  color: #1da1f2;
}

.comment-img {
  width: 25%;
  text-align: center;
  float: left;
}

.comment-img img{
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.comment-text-form {
  width: 75%;
  float: left;
}
.comment-text-form input {
  width: 70%;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  background: #062489;
  color: #fff;
  border: 1px solid #163fcb;
}

.comment-text-form a.custom-btn {
  width: 25%;
  text-align: center;
  float: right;
}
.single-comment {
  height: 250px;
  padding: 20px;
  box-shadow: 0 0 2px #f2f2f2;
}
.single-blog-area a.gradient-btn {
  margin-left: 20px;
}
.single-blog-area a{
  color: #fff;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}
.single-blog-area a:hover{
  color: #787877;
  -webkit-transition: all .3s;
  -o-transition: all 3s;
  transition: all .3s;
}

.single-blog-contact input {
  width: 48%;
  border-radius: 10px;
}

.single-blog-contact input:nth-child(2) {
  margin-left: 20px;
  float: right;
}

.single-blog-contact input, 
.single-blog-contact textarea{
  border: 1px solid #163fcb;
  padding: 10px;
  padding-left: 20px;
  text-transform: capitalize;
  color: #fff;
  background:#062489;
}

.single-blog-contact textarea{
  width: 100%;
}

.single-blog-area small span {
  padding-left: 50px;
}

small{
  font-size: 14px;
}


/*===================
comman css
======================*/

.section-padding{
  padding: 90px 0;
}

.space-10{
  height: 10px;
}

.space-20{
  height: 20px;
}

.space-30{
  height: 30px;
}

.space-40{
  height: 40px;
}
.space-50{
  height: 50px;
}

.space-60{
  height: 60px;
}

.space-70{
  height: 70px;
}

.space-80{
  height: 80px;
}

.space-90{
  height: 90px;
}

.space-100{
  height: 100px;
}

.mr-20{
  margin-right:20px;
}

a.gradient-btn {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  background: -webkit-linear-gradient(right,#fe998b,#fa768d,#f78ca0);
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  text-align: center;
    
}

a.gradient-btn:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: -webkit-linear-gradient(right,#4867cf,#fa768d,#fe998b);
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 5px;
  opacity: 0;

}

a.gradient-btn:hover:after{
  opacity: 1;
}

.heading h5 {
  font-size: 16px;
  text-transform: uppercase;
}

.about-mid-img img, 
.document-flag img, 
.single-team-img img,
.welcome-img img{
  height: 100%;
  width: 100%;
}

::-webkit-input-placeholder{color:#fff}
:-ms-input-placeholder{color:#fff}
::-ms-input-placeholder{color:#fff}
::placeholder{color:#fff}


.light-mode {
  background-color: #f7f8fa;
  color: #000000;
}

.dark-mode {
  background-color: #000000;
  color: #cbe5ff;
  position: relative;
}

.light-Contact-text h5{
  color: black;
}

.light-Contact-text h1{
  color: black;
}

.light-Contact-text a{
  color: black;
}

.light-Contact-text small{
  color: black;
}

.light-Contact-text input{
  color: black;
}

.light-Contact-text input::placeholder {
  color: black;
}

.light-Contact-text textarea::placeholder {
  color: black;
}


.dark-Contact-text h5{
  color: white;
}

.dark-Contact-text h1{
  color: white;
}

.dark-Contact-text a{
  color: white;
}

.dark-Contact-text small{
  color: white;
}

.dark-Contact-text input{
  color: white;
}

.dark-Contact-text input::placeholder {
  color: white;
}

.dark-Contact-text textarea::placeholder {
  color: white;
}


form .gradient-btn {
  border-radius: 20px;
  background-color: #fca897;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

form .gradient-btn:hover {
  background-color: #fc8776; 
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); 
  transform: translateY(-3px);
}


.high1, .high2, .high3, .high4 {
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.high1:hover, .high2:hover, .high3:hover, .high4:hover {
  letter-spacing: 1px;
  transform: scale(1.3);
}

.singlecontainer {
  display: flex;
  justify-content: space-between;
}

.container-card1 {
  
}

.dark-container-card1{
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  flex: 1; 
  margin: 10px;
  position: relative;
  border: 2px solid transparent;
  
  background-clip: padding-box;
  border-radius: 45px;
  padding: 40px;
}

.light-container-card1{
  background: #0a254d;
  flex: 1; 
  margin: 10px;
  position: relative;
  border: 2px solid transparent;
  
  background-clip: padding-box;
  border-radius: 45px;
  padding: 40px;
}

.bg-blue-box{
  position: relative;
}

.bg-blue-box::after{
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
  border-radius: 45px;
}

.bg-blue-box::after {
  background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
}


.bg-green-box{
  position: relative;
}

.bg-green-box::after{
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
  border-radius: 45px;
}

.bg-green-box::after {
  background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
}

/* .single-about-img {
  
  width: 100%;
  height: auto;
} */

.aboutcircle{
  
    margin-bottom: 32px;

}

/* .single-about-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100%; 
  transform: translateY(100%); 
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
}

.single-about:hover .single-about-text {
  transform: translateY(0); 
  opacity: 1;
} */

/* .single-about h4 {
  margin: 10px 0;
}

.single-about p {
  margin: 0;
} */



.social-container {
  width: 300px;
 
  text-align: center;
}

.social-icons {
  left: 0;
  padding: 0;
  list-style: none;
  margin: 1em;
}

.social-icons li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .social-icons li {
    display: inline-block;
    position: relative;
    margin: 0.10em;
   font-size: 15px;
  }

  .single-footer h4{
    text-align: center;
  }

  .single-footer li a {
    margin-left: 40%;
  }

  /* .single-footer .linkcon {
    margin-left: 25%;
  } */
  .social-icons a:before {
    width: 15px;
    
  }
 
  .social-icons i {
    width: 15px;
    
  }

  .social-icons .fa-instagram{
    font-size: 20px;
  }

  .social-icons .fa-twitter {
    font-size: 20px;
  }

  .social-icons .fa-linkedin {
    font-size: 20px;
  }

  .social-icons .fa-facebook {
    font-size: 20px;
  }
}

.social-icons i {
  color: #fff;
  position: absolute;
  top: 21px;
  left: 21px;
  transition: all 0.3s ease-out; /* Assuming $timing is 0.3s */
}

.social-icons a {
  display: inline-block;
  position: relative;
}

.social-icons a:before {
  content: " ";
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, #ff4081, #2196f3); /* Assuming $iconColor and $accent */
  transition: all 0.3s ease-out; /* Assuming $timing is 0.3s */
}

.social-icons a:hover:before {
  transform: scale(0);
  transition: all 0.3s ease-in;
}

.social-icons a:hover i {
  transform: scale(2.2);
  color: #ff4081; /* Assuming $iconColor */
  background: -webkit-linear-gradient(45deg, #ff4081, #2196f3); /* Assuming $iconColor and $accent */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in; /* Assuming $timing is 0.3s */
}



/*other service*/

.Reservice .service-itemre {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  
}

.Reservice .service-itemre,
.Reservice .service-itemre .service-icon,
.Reservice .service-itemre a {
  transition: 0.5s;
}

.Reservice .service-itemre:hover {
  background: rgba(102, 16, 242, 0.09);
  border: 1px ;
}


.Reservice .service-itemre:hover .service-icon,
.Reservice .service-itemre:hover a {
  background: var(--bs-white) !important;
}

/* .Reservice .service-itemre:hover a:hover {
  background: var(--bs-primary) !important;
  color: var(--bs-white);
} */



.Reservice .service-itemre .btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; 
  text-align: center;
}

.Reservicebtn{
            background-image: linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%);
            margin: 10px;
            padding: 15px 45px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
}

.Reservicebtn:hover{
  background-position: right center;
  color: #fff;
  text-decoration: none;

}

/*Our mission and Our vision*/
@media (max-width: 768px) {
  .singlecontainer {
    flex-direction: column;
  }
}

/*Reservice Section*/

.light-Remain-Service h4, .light-Remain-Service p{
  color: black;
}

.dark-Remain-Service h4, .dark-Remain-Service p{
  color: white;
}

/*contact page*/
.contactform {
  margin-left: 25%;
}

@media (max-width: 768px) {
  .contactform{
    margin-right: 20%;
  }
}


/*footer*/

.quick-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 10px; /* Space between items */
}

@media (min-width: 768px) {
  .quick-links {
    grid-template-columns: 1fr; /* Single column on larger screens */
  }
}

.quick-links li {
  list-style: none;
}

.quick-links li a {
  text-decoration: none;
}

.address-list {
  list-style: none;
  padding: 0;
}

.addressre {
  margin-left: 35px;
}


@media (max-width: 768px) {
  .single-footer h4,
  .address-list {
    text-align: center; 
  }
  
  .address-list li {
    margin-left: 0; 
    display: block;
  }
}

.linkcon {
  list-style: none;
  padding: 0;
}

.linkcon li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .single-footer h4,
  .linkcon {
    text-align: center; /* Center align text for mobile view */
  }
  
  .linkcon li {
    justify-content: center; /* Center icons and text */
  }

  /* .dark-single-footer .quick-links li a{
    color: white;
  } */
}

@media (max-width: 768px) {
  .singlecontainer{
    height: 100%;
  }
  .container-card1{
    height: 600px;
  }
  

}

@media (max-width: 768px) {
  .about-area{
   
    width: 80%;
    height:100%;
    margin-left: 10%;
  }
}


@media screen and (max-width: 600px) {
  .welcome-right{
    margin-top: -500px !important;
  }

  .welcome-right h1{
    font-size: 20px !important;
  }

  .service-itemre {
    height: 650px !important;
  }

  .Reservicebtn{
    align-items: center;
    text-align: center !important;
  }

  .light-mode {
    width: 100% !important;
  }

  .Reserviceh2{
    font-size: 16px;
  }

  .service_s2-layout .text-block {
    align-items: center !important;
    text-align: center !important;
    margin-left: 30%;
  }

  .service_s2-heading{
    font-size: 16px !important;
  }
 
  .containerrd .card_content1 h3{
    font-size: 12px !important;
  }

  .loginbtn{
    margin-left: 45%;
  }
}