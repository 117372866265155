:root {
    --primary-color: rgb(23, 133, 130);
}

.case-study-card {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
    width : 600px;
    height: auto;
  }
  

  .case-study-card .card-img {
    height: 100%;
    object-fit:fill;

  }
  
  .case-study-card .card-body {
    padding: 20px;
  }

  .casestudies-description {
    text-align: justify;
    color: black;
    font-size: 10px;
  }

  .casetstudies .casestudies-button {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 25px;
    transition: all 0.3s;
    color: var(--primary-color);
    background-color: #fff;
  }
  
  .casestudies-date {
    color: rgb(225, 76, 43);
  }

  .casestudies-title {
    color: rgb(51, 34, 203);
  }