.card-wrapper {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: row;
  }
  
  .card {
    position: relative;
    top: 50%;
    left: 20%;
    margin-left: 20px;
    width: 350px;
    height: 450px;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: 0.5s;
  }
  
  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000;
    transition: 0.5s;
  }


  .card-image img{
    background-size: cover;
   
  }
  
  .card:hover .img {
    transform: rotateY(180deg);
    
  }
  
  .card:hover .card-image {
    transform: translateY(-100px);
    transition: all 0.9s;
  }
  
  .social-iconsTeam {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: flex;
  }
  
  .social-iconsTeam li {
    list-style: none;
  }
  
  .social-iconsTeam li a {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #fff;
    font-size: 23px;
    color: #333;
    font-weight: bold;
    margin: 0 6px;
    transition: 0.4s;
    transform: translateY(200px);
    opacity: 0;
  }
  
  .card:hover .social-iconsTeam li a {
    transform: translateY(0);
    opacity: 1;
  }
  
  .social-iconsTeam li a:hover {
    background: blue;
    transition: 0.2s;
  }
  
  .social-iconsTeam li a .fab {
    transition: 0.8s;
  }
  
  .social-iconsTeam li a:hover .fab {
    transform: rotateY(360deg);
    color: #fff;
  }
  
  .card:hover li:nth-child(1) a {
    transition-delay: 0.1s;
  }
  
  .card:hover li:nth-child(2) a {
    transition-delay: 0.2s;
  }
  
  .card:hover li:nth-child(3) a {
    transition-delay: 0.3s;
  }
  
  .card:hover li:nth-child(4) a {
    transition-delay: 0.4s;
  }
  
  .details {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 120px;
    z-index: 1;
    padding: 10px;
  }
  
  .details h2 {
    margin: 30px 0;
    padding: 0;
    text-align: center;
  }
  
  .job-title {
    font-size: 1rem;
    line-height: 2.5rem;
    color: #333;
    font-weight: 300;
  }
  

  .jane {
    position: absolute;
    bottom: -120px;
    left: 0;
    background: #b5cef5;
    width: 100%;
    height: 120px;
    z-index: 3;
    padding: 10px;
    opacity: 0;
    transition: bottom 0.4s, opacity 0.4s;
  }

.card:hover .jane {
  bottom: 0;
  opacity: 1;
  transition-delay: 0.5s;
}

.jane h2 {
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.jane .job-title {
  font-size: 14px;
  color: #666;
}