.service-slider1 {
    height: 300px;
    
    display: block;
    margin: auto;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
  }
  
.animation_slider{
    scroll-behavior: smooth;
    
}