/*==================== GOOGLE FONTS ====================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

/*==================== VARIABLES CSS ====================*/
:root {
  /*========== Colors ==========*/
  --text-color: #000000;
  --bg-color: #222222;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  --normal-font-size: 0.938rem;
}

@media screen and (min-width: 968px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/*==================== BASE ====================*/
/* *,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* body {
  font-size: var(--normal-font-size);
  background-color: var(--bg-color);
  color: var(--text-color);
  font-weight: 400;
  font-family: var(--body-font);
  transition: all 0.2s ease;
} */

/*==================== REUSABLE CSS CLASSES ====================*/
.dark-containerrd {
  position: relative;
  height: 650px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0;
  min-height: 100vh;
  display: grid;
  place-items: center;
  background:
    linear-gradient(90deg, black calc(50px - 1px), transparent 1%) center / 50px 50px,
    linear-gradient(black calc(50px - 1px), transparent 1%) center / 50px 50px,
    white; 
}

.light-containerrd{
  position: relative;
  height: 650px;
  background-color: #f6f6f6;
  opacity: 1;
  background-image: radial-gradient(#4444ee 1.6500000000000001px, #f6f6f6 1.6500000000000001px);
  background-size: 33px 33px;
}
/*==================== SERVICE CARD ====================*/
.card__containerrd {
  
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
  width: 100%;
  max-width: 90%;
  margin: auto;
  padding: 60px 0;
}

.card__bx11 {
  margin-top: 50px;
  --dark-color: #2e2e2e;
  --dark-alt-color: #777777;
  --white-color: #ffffff;
  --button-color: #333333;
  --transition: 0.5s ease-in-out;
  font-family: inherit;
  height: 450px; /* Increased height to fit content */
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column; /* Makes sure content aligns vertically */
  justify-content: center;
  align-items: center;
  background: var(--dark-color);
  transition: var(--transition);
}

.card__bx11::before,
.card__bx11::after {
  content: " ";
  position: absolute;
  z-index: -10;
  transition: var(--transition);
}

.card__bx11::before {
  inset: -10px 50px;
  border-top: 4px solid var(--clr);
  transform: skewY(15deg);
  border-bottom: 4px solid var(--clr);
}

.card__bx11:hover::before {
  inset: -10px 40px;
  transform: skewY(0deg);
}

.card__bx11::after {
  inset: 60px -10px;
  border-left: 4px solid var(--clr);
  transform: skew(15deg);
  border-right: 4px solid var(--clr);
}

.card__bx11:hover::after {
  inset: 40px -10px;
  transform: skew(0deg);
}

.card__data1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.card__icon {
  
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: white;
  background-color: var(--dark-color);
  transition: var(--transition);
  box-shadow: 0 0 0 4px var(--dark-color), 0 0 0 6px var(--clr);
}

.card__icon svg, .card__icon img {
  max-width: 100%;
  max-height: 100%;
}

.card__bx11:hover .card__data1 .card__icon {
  color: var(--dark-color);
  background-color: var(--clr);
  box-shadow: 0 0 0 4px var(--dark-color), 0 0 0 300px var(--clr);
}

.card__content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.card__content1 h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--white-color);
  transition: var(--transition);
}

.card__bx11:hover .card__content1 h3 {
  color: var(--dark-color);
}

.card__content1 p {
  font-size: 0.9rem;
  color: var(--dark-alt-color);
  transition: var(--transition);
}

.card__bx11:hover .card__content1 p {
  color: var(--dark-color);
}


@media (max-width: 768px){
  .light-containerrd{
    height: 100%;
  }

  .dark-containerrd{
    height: 100%;
  }
}