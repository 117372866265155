
:root {
    --blue-700: #0c1a51;
    --transparent: transparent;
    --pink-400: #823ee9;
    --blue-400: #224ae8;
    --black: black;
    --blue-500: #1a38ae;
    --white-200: #e9edfd;
    --white: white;
    --gray-300: #bac7f8;
    --pink-600: #3d2359;
    --radius-16px: 16px;
    --radius-32px: 32px;
    --seconday-color: #737373;
    --blue-600: #0c184f;
    --pink-500: rgba(87, 37, 162, .97);
    --white-201: white;
    --black-2: black;
    --white-gradient-sm: rgba(250, 250, 250, 0.1);
    --white-gradient-md: rgba(250, 250, 250, 0.15);
  }

.service_s2-wrapper {
    flex-direction: column;
    align-items: center;
    display: flex;
    max-width: 100%;
  }

  .service_s2-img {
    width: 29.4375em;
  }
  
  .service_s2-img.mobile {
    display: none;
  }

  .service_s2-layout {
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    background-color: var(--white-gradient-sm);
  
    color: var(--white-200);
    /* background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65dc71d098c8b56a0b1345ff_Frame%20427320442.png");
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: auto 100%; */
    border-radius: 1em;
    grid-template-rows: min-content auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    min-height: 40em;
    margin-left: -2em;
    margin-right: -2em;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    display: grid;
    overflow: hidden;
    width: 80%;
  }

  
.blob {
  position: absolute;
  left: 0;
  top: 25%;
  width: 150px;
  height: 150px;
  background: linear-gradient(
    180deg,
    rgba(47, 184, 255,0.42) 31.77%,
    #cddef7 100%
  );
  mix-blend-mode: color-dodge;
  -webkit-animation: move1 25s infinite alternate;
          animation: move1 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}


/* .blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
          filter: blur(30px);
  box-shadow:
    inset 0 0 0 5px rgba(255,255,255, 0.6),
    inset 100px 100px 0 0px #fa709a,
    inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
} */


@-webkit-keyframes move1 {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move1 {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

  .text-block {
    background-color: var(--white-200);
    color: var(--blue-400);
    border-radius: 24px;
    padding: 8px 12px;
    font-family: Gilroy, sans-serif;
    font-size: .75em;
    font-weight: 500;
    display: inline-block;
  }
  
  .h-44 {
    font-size: 2.75em;
  }
  
  .h-44.service_s2-heading {
    color: var(--white-200);
    font-weight: 600;
    line-height: 107%;
  }

  .service_s2-content-wrapper {
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    flex-direction: column;
    width: 100%;
    max-width: 33em;
    margin-bottom: 3.1875em;
    display: flex;
  }

  .service_s2-content {
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    text-align: left;
    background: var(--white-gradient-md);
    border-radius: 12px;
    flex-direction: row;
    align-items: flex-start;
    padding: 1em;
    display: flex;
  }

  .number {
    color: #bac7f8;
    font-size: 1.25em;
    font-weight: 700;
  }

  .p1 {
    font-family: Gilroy, sans-serif;
    font-size: 1em;
    font-weight: 500;
    line-height: 172%;
  }

  .v-flex {
    flex-direction: column;
    display: flex;
  }
  
  .v-flex.gap-64 {
    grid-column-gap: 4em;
    grid-row-gap: 4em;
  }

  .servicontainer4{
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
   
  }



  .page-padding.career_s2-padding {
    margin-top: -18.75em;
  }
  
  #w-node-_27ba9e62-e568-11de-110f-f7cba7538575-299e36c2 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: start;
    justify-self: start;
  }

  #w-node-_27ba9e62-e568-11de-110f-f7cba7538579-299e36c2 {
    grid-area: span 2 / span 1 / span 2 / span 1;
  }

  @media screen and (max-width: 767px) {
    .service_s2-layout {
        text-align: center;
        background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65dca7a29296494931c7bc21_Frame%20427320444.png");
        background-position: 50% 0;
        background-size: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: none;
        padding-top: 2.75em;
        padding-bottom: 2.75em;
        display: flex;
      }
      
  }

  