#loader-wrapper2 {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  overflow: hidden; 
}

.animation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10; /* Ensure loader text appears above circles */
}

.text-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.bottom-container11,
.top-container11 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  position: relative;
}

.bottom-container11 {
  color: white;
  width: auto;
}

.top-container11 {
  background-color: red;
  color: black;
  position: absolute;
  clip-path: circle(13% at 85% 50%);
  animation: circleMove 15s ease-in-out infinite;
  width: auto;
  padding: 0 10px;
}

@keyframes circleMove {
  0%, 100% {
    clip-path: circle(13% at 85% 50%);
  }
  50% {
    clip-path: circle(13% at 15% 50%);
  }
}

.right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh; 
}

#loader2 {
  width: 75px;
  height: 75px;
  position: relative;
}

#percentage2 {
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: white;
  z-index: 2;
}

/* Circles animation */
.area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  z-index: 1; /* Place circles under the loader */
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}


/*Infinity animation*/


.center { 
  
      display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 50px;
}
.circle,
.circle2 {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  
}

.dot3,
.dot4 {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #9f93fa;
  border-radius: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

/* First circle */
.dot3 {
  animation-name: moveParticle;
}

/* Second circle */
.dot4 {
  animation-name: moveParticle2;
}

@keyframes moveParticle {
  0%,
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: scale(2) translate(-20px, 20px);
    opacity: 0.5;
  }
}

@keyframes moveParticle2 {
  0%,
  100% {
    transform: scale(2) translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: scale(1) translate(20px, -20px);
    opacity: 0.7;
  }
}

.dot3:nth-child(1),
.dot4:nth-child(1) {
  left: calc(50% + 50px * cos(0deg) - 5px);
  top: calc(50% + 50px * sin(0deg) - 5px);
  animation-delay: 0s;
}
.dot3:nth-child(2),
.dot4:nth-child(2) {
  left: calc(50% + 50px * cos(30deg) - 5px);
  top: calc(50% + 50px * sin(30deg) - 5px);
  animation-delay: 0.2s;
}
.dot3:nth-child(3),
.dot4:nth-child(3) {
  left: calc(50% + 50px * cos(60deg) - 5px);
  top: calc(50% + 50px * sin(60deg) - 5px);
  animation-delay: 0.4s;
}
.dot3:nth-child(4),
.dot4:nth-child(4) {
  left: calc(50% + 50px * cos(90deg) - 5px);
  top: calc(50% + 50px * sin(90deg) - 5px);
  animation-delay: 0.6s;
}
.dot3:nth-child(5),
.dot4:nth-child(5) {
  left: calc(50% + 50px * cos(120deg) - 5px);
  top: calc(50% + 50px * sin(120deg) - 5px);
  animation-delay: 0.8s;
}
.dot3:nth-child(6),
.dot4:nth-child(6) {
  left: calc(50% + 50px * cos(150deg) - 5px);
  top: calc(50% + 50px * sin(150deg) - 5px);
  animation-delay: 1s;
}
.dot3:nth-child(7),
.dot4:nth-child(7) {
  left: calc(50% + 50px * cos(180deg) - 5px);
  top: calc(50% + 50px * sin(180deg) - 5px);
  animation-delay: 1.2s;
}
.dot3:nth-child(8),
.dot4:nth-child(8) {
  left: calc(50% + 50px * cos(210deg) - 5px);
  top: calc(50% + 50px * sin(210deg) - 5px);
  animation-delay: 1.4s;
}
.dot3:nth-child(9),
.dot4:nth-child(9) {
  left: calc(50% + 50px * cos(240deg) - 5px);
  top: calc(50% + 50px * sin(240deg) - 5px);
  animation-delay: 1.6s;
}
.dot3:nth-child(10),
.dot4:nth-child(10) {
  left: calc(50% + 50px * cos(270deg) - 5px);
  top: calc(50% + 50px * sin(270deg) - 5px);
  animation-delay: 1.8s;
}
.dot3:nth-child(11),
.dot4:nth-child(11) {
  left: calc(50% + 50px * cos(300deg) - 5px);
  top: calc(50% + 50px * sin(300deg) - 5px);
  animation-delay: 2s;
}
.dot3:nth-child(12),
.dot4:nth-child(12) {
  left: calc(50% + 50px * cos(330deg) - 5px);
  top: calc(50% + 50px * sin(330deg) - 5px);
  animation-delay: 2.2s;
}


/* text animation */

@keyframes charColor {
  0% {
    color: #fff;
 }
  50% {
    color: #5c5c5c;
 }
  100% {
    color: #fff;
 }
}

.loadingScreen {
 
  
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900000;
  overflow: hidden;
  background: #fff;
}
.loadingScreen .loadingScreen__text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 260px;
  bottom: 0;
  margin: auto;
  text-align: center;
  width: auto;
  height: 100px;
  color: #ddd;
  text-transform: uppercase;
  font-size: 30px;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span {
  padding: 5px;
  vertical-align: middle;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(1) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 100ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(2) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 200ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(3) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 300ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(4) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 400ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(5) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 500ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(6) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 600ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(7) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 700ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(8) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 800ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.loadingScreen .loadingScreen__text .loadingScreen__text__span:nth-child(9) {
  display: inline-block;
  animation-name: charColor;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 900ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}



.loader {
  margin-right: 100px;
  width: fit-content;
  font-size: 60px;
  font-family: system-ui,sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #00000000;
  -webkit-text-stroke: 1px white;
  background:
    radial-gradient(0.71em at 50% 1em,#ADD8E6 99%,#0000 101%) calc(50% - 1em) 1em/2em 200% repeat-x text,
    radial-gradient(0.71em at 50% -0.5em,#ADD8E6 99%,#ADD8E6 101%) 50% 1.5em/2em 200% repeat-x text;
  animation: 
    l10-0 .8s linear infinite alternate,
    l10-1  4s linear infinite;
}
.loader:before {
  content: "Cogniglob";
}
@keyframes l10-0 {
  to {background-position-x: 50%,calc(50% + 1em)}
}
@keyframes l10-1 {
  to {background-position-y: -.5em,0}
}

@media only screen and (max-width: 600px){

  #percentage2{
    padding-bottom: 80px;
    align-items: center;
    text-align: center;
    padding-right: 35%;
  }

  .loader {
    margin-left: 50px;
  }

  }