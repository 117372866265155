.header {
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-area {
  
}

.navbarheadtitle { 
  
  background: linear-gradient(90deg, rgb(10, 24, 67) 10%, rgb(8, 8, 44) 50%, rgba(2,10,21,1) 70%);
}

@keyframes type {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

.header-reduced {
  /* padding: 0.1rem 0 !important; */
  transition: all 0.5s ease;
}

.background-header{
  z-index: 9999999999999999 !important;
}

.light-background-header {
 
  border-radius: 0px 0px 25px 25px;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  background-color: white !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  transition: all 0.5s ease;
  padding: 5px;
 
 
}



.dark-background-header{
  border-radius: 0px 0px 25px 25px;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  background-color: black !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  transition: all 0.5s ease;
  padding: 5px;
  
}


.dropdown3 {
  position: relative;
  display: inline-block;
}

.dropdown-menu2 {
  display: none;
  position: absolute;
  background: white;
  
  z-index: 1;
  margin-top: 10px;
}

.dropdown-menu2 li a {
  font-size: 12px;
  color: white;
}

.dropdown3 ul a{
  color: white;
}

.dropdown3 .navdrop {
  color: black;
}

.navdrop:hover {
  color: white !important;
  font-size: 13px;
}

.dropdown3:hover .dropdown-menu2 {
  display: block;
}

.dropdown-menu2 li {
  background-color: white;
  list-style: none;
  border: 2px solid #cddef7;
}

.dropdown-menu2 li a {
  color: black;
  text-decoration: none;
}

.dropdown-menu2 li a:hover {
  background-color: #ddd;
}


/*mobile view*/
/* Mobile Navigation */
.mobile-nav {
display: none;
position: absolute;
top: 70px; /* Adjust according to your design */
left: 0;
width: 100%;
background-color: white; /* or use var(--your-bg-color) */
z-index: 9999;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.light-mobile-nav{
  background-color: white;
  color: black;
}

.dark-mobile-nav {
  background-color: black;
  color: white !important;
}

.light-mobile-nav a{
  color: black;
}

.dark-mobile-nav nav li a{
  color: white !important;
}

.dark-mobile-nav nav li a:hover{
  color: black !important;
}

.dark-mobile-nav nav li .dropdown1 li a{
  color: black !important;
}

.dark-mobile-nav nav .dropdown-menu5 a{
  color: black;
}

.mobile-nav ul {
list-style: none;
padding: 0;
margin: 0;
}

.mobile-nav ul li {
padding: 15px;
text-align: center;
}

.mobile-nav ul li a {
color: black;
text-decoration: none;
}

.mobile-nav ul li:hover {
background-color: #f0f0f0; /* Light hover effect */
}

/* Dropdown styling */
.mobile-nav .dropdown {
padding: 0;
margin: 0;
list-style-type: none;
}

.mobile-nav .dropdown li {
padding: 10px 20px;
background-color: #f9f9f9;
text-align: center;
}

.mobile-nav .dropdown li a {
color: #333;
}

.mobile-nav .dropdown li:hover {
background-color: #e0e0e0;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
font-size: 1.5rem;
cursor: pointer;
}

.mobile-iconcolor{
color: white;
}



/* Show mobile nav on small screens */
@media (max-width: 992px) {
.mobile-nav {
  display: block;
}

/* Ensure the desktop navigation is hidden */
.main-menu {
  display: none;
}
}
/* Align mobile menu icon on the right */
@media (max-width: 992px) {
.mobile-menu-icon {
  float: right;
}

.mobile-login-btn {
  margin-top: 15px;
  text-align: center;
}
}

/* Ensure dropdown is displayed on click */
.mobile-nav .dropdown {
display: none;
}

.mobile-nav .dropdown.open {
display: block;
}

/* Ensure the user dropdown is hidden by default */
.user-menu {
position: relative;
}

.user-menu .dropdown-menu1 {
display: none;
position: absolute;
right: 0;
background-color: #ffffff;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
padding: 10px;
z-index: 100000;
}

.user-menu .dropdown-menu5 {
  display: none;
  position: absolute;
  align-items: center;
  margin-left: 35%;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100000;
  }

.user-menu:hover .dropdown-menu1 {
display: block;
}

.user-menu:hover .dropdown-menu5 {
  display: block;
}

.user-menu .dropdown-menu1 a {
  color: black;
}

.user-icon {
cursor: pointer;
}

.user-icon1{
  margin-left: 50%;
}

.user-menu a {
display: block;
padding: 10px;
text-decoration: none;
color: black;
}

.user-menu a:hover {
background-color: #f0f0f0; /* Add hover effect */
}

@media (max-width: 768px) {
.logo-area a{
  width:50px;
  height:50px;
}

.logo-area a img{
  width: 40px;
  height: 40px;
}
}

@media screen and (max-width: 600px) {
  .header-area {
    width: 100%;
    
   }

   

  .toggle-color-mode-btn{
    display: none;
  }


} 

.toggle-color-mode-btn {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 15px;
}

.toggle-color-mode-btn1 {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 15px;
}

body.light{
  background-color: white;
  color: black;
}

body.light a{
  color: black;
}

body.dark a{
  color: white;
}

body.light.h4{
  color: black !important;
}

.body.light.single-footer h4{
  color: black !important;
}

body.dark.h4{
  color: white !important;
}

body.dark{
  background-color: black;
  color: white;
}

body.light.single-about-area {
  background-color: #f6f6f6;
  opacity: 1;
  background-image: radial-gradient(#4444ee 1.6500000000000001px, #f6f6f6 1.6500000000000001px);
  background-size: 33px 33px;
}

body.dark.single-about-area {
  background-color: black;
}

body.light.single-about-area.singlecontainer.container-card1{
  background: #0a254d;
  flex: 1; 
  margin: 10px;
  position: relative;
  border: 2px solid transparent;
  
  background-clip: padding-box;
  border-radius: 45px;
  padding: 40px;
}

body.dark.single-about-area.container-card1{
  background: linear-gradient(71deg, #080509, #1a171c, #080509) !important;
  flex: 1; 
  margin: 10px;
  position: relative;
  border: 2px solid transparent;
  
  background-clip: padding-box;
  border-radius: 45px;
  padding: 40px;
}



.navbarheadtitle .toggle-color-mode-btn {
  color: white;
}

.navbarheadtitle .user-icon{
  color: white;
}
